/**
 *     ▄▄▄▄▄▄▄▄▄▄▄  ▄       ▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄        ▄                                  
 *    ▐░░░░░░░░░░░▌▐░▌     ▐░▌▐░░░░░░░░░░░▌▐░░▌      ▐░▌                                 
 *    ▐░█▀▀▀▀▀▀▀█░▌ ▐░▌   ▐░▌ ▐░█▀▀▀▀▀▀▀█░▌▐░▌░▌     ▐░▌                                 
 *    ▐░▌       ▐░▌  ▐░▌ ▐░▌  ▐░▌       ▐░▌▐░▌▐░▌    ▐░▌                                 
 *    ▐░█▄▄▄▄▄▄▄█░▌   ▐░▐░▌   ▐░▌       ▐░▌▐░▌ ▐░▌   ▐░▌                                 
 *    ▐░░░░░░░░░░░▌    ▐░▌    ▐░▌       ▐░▌▐░▌  ▐░▌  ▐░▌                                 
 *    ▐░█▀▀▀▀▀▀▀█░▌   ▐░▌░▌   ▐░▌       ▐░▌▐░▌   ▐░▌ ▐░▌                                 
 *    ▐░▌       ▐░▌  ▐░▌ ▐░▌  ▐░▌       ▐░▌▐░▌    ▐░▌▐░▌                                 
 *    ▐░▌       ▐░▌ ▐░▌   ▐░▌ ▐░█▄▄▄▄▄▄▄█░▌▐░▌     ▐░▐░▌                                 
 *    ▐░▌       ▐░▌▐░▌     ▐░▌▐░░░░░░░░░░░▌▐░▌      ▐░░▌                                 
 *     ▀         ▀  ▀       ▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀        ▀▀                                  
 *          ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄         ▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄ 
 *         ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌
 *         ▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀█░▌▐░▌       ▐░▌ ▀▀▀▀█░█▀▀▀▀ ▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀▀▀▀█░▌
 *         ▐░▌       ▐░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░▌          ▐░▌       ▐░▌
 *         ▐░█▄▄▄▄▄▄▄█░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░█▄▄▄▄▄▄▄▄▄ ▐░█▄▄▄▄▄▄▄█░▌
 *         ▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌
 *         ▐░█▀▀▀▀█░█▀▀ ▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀█░█▀▀ 
 *         ▐░▌     ▐░▌  ▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░▌          ▐░▌     ▐░▌  
 *         ▐░▌      ▐░▌ ▐░█▄▄▄▄▄▄▄█░▌▐░█▄▄▄▄▄▄▄█░▌     ▐░▌     ▐░█▄▄▄▄▄▄▄▄▄ ▐░▌      ▐░▌ 
 *         ▐░▌       ▐░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌     ▐░▌     ▐░░░░░░░░░░░▌▐░▌       ▐░▌
 *          ▀         ▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀       ▀       ▀▀▀▀▀▀▀▀▀▀▀  ▀         ▀ 
 *                                                                                                                                             
 * -------------------------------------------------------------------------------------------------------------------------------
 *  "An Axon is the long threadlike part of a nerve cell along which impulses are conducted from the cell body to other cells."
 *
 * @author 
 *      Cameron Van Orman
 *      <cameron@efelle.com>
 *      Slack @cameronv
 *
 * @reference
 * 		Introduction : http://daux.efellemedia.com/SOP/master/axon-router/introduction
 *		Base Javascript: http://daux.efellemedia.com/SOP/master/axon-router/base-js
 *      Code Standards: http://daux.efellemedia.com/SOP/master/code-standards/javascript
 *
 * @usage
 *      Store javascript functions under the FEATURES namespace to give your functions global scope.
 *      Pass your functions to the Site Router (router.js) under the relevant page name. 
 *		Page names are defined by a body class on your template (.emt) file.
 *
 *      NOTE: You must have a body class on the template you're targeting in order for your page specific scripts to run.
 *      i.e <body class='home'> if you're on the frontpage template.
 * 
 *      NOTE: In order for your javascript to run on a specific page you need to declare 
 *		the function in the FEATURES global namespace, then call the function in router.js 
 *		where you've setup your page specific body class.
 *
 *
 * FEATURES SETUP
 * -------------
 *
 *  NOTE: Your FEATURES variable is global scope.
 *  Javascript Global Scope: http://stackoverflow.com/a/500459 
 *  ie. FEATURES.yourFunctionName();
 * 
 *  NOTE: Our global variable, AKA namespace, "FEATURES" is all caps. 
 *  ALL global scoped variables should be written in all caps
 *  to signify hierarchy in the application,
 *
 *  Write your page specific functions here, then call the function for 
 *  the pages you need it on below.
 *
 * @usage
 *      var FEATURES = {
 *          yourFunctionName: function() {
 *           -- Write/paste your script here --
 *          },
 *      };
 */
var FEATURES = {
	/**
	 * Defer Images - Setup
	 *
	 * Add class 'lazy' to image or div with a background image or src attribute.
	 * 
	 * @param $imgs {string} - pass in a string or html class.
	 * @usage FEATURES.PLUGIN.deferImages('.lazy');
	 *
	 * Dependencies
	 * - jQuery
	 * - jQuery.lazyload (https://github.com/tuupola/jquery_lazyload)
	 */
	deferImages: function(imgs) {
		// Image array
		var $imgs = $(imgs);
		// Cache the window object - console.log(window);
		var $win = $(window);

		/**
		* Load Visible Images
		* Load images if they're visible
		*/
		function loadVisible($els, trigger) {
			$els.filter(function() {
				var rect = this.getBoundingClientRect();

				return rect.top >= 0 && rect.top <= window.innerHeight;
			}).trigger(trigger);
		}

		/**
		 * jQuery.lazyload
		 * Initialize lazyload with fadeIn effect and failure_limit.
		 * Listening on event 'lazylazy'
		 */
		$imgs.lazyload({
			effect: "fadeIn",
			failure_limit: Math.max($imgs.length - 1, 0),
			event: 'lazylazy'
		});

		/**
		 * Load images on scroll with event lazylazy.
		 */
		$win.on('scroll', function() {
			loadVisible($imgs, 'lazylazy');
		});
	},

	gMaps: { 
	    /**
	     * Init Function for Google Maps with pin
	     *
	     * @namespace HYDROGEN.gMaps.initialize('#map', '.coords');
	     * @param mapTarget = id of map
	     * @param coords    = class of element with latitude/longitude coordinates.
	     *            Add data-lat and data-lng attributes to an element
	     *            with the value being the module tag.
	     */
	    initialize: function(mapTarget, coords) {
	      /**
	       * Map Defaults
	       */
	      var bounds = new google.maps.LatLngBounds();          // New LatLngBounds object from Google maps API
	      var infoWindow = new google.maps.InfoWindow();          // New InfoWindow object from Google maps API
	      var geocoder = new google.maps.Geocoder();            // New Geocoder object from Google maps API
	      var markers = [];                         // Array for markers location
	      var infoWindowTemplate;                     // Info Window Path (pop up when marker is clicked in map)
	      var markerPath = '/graphics/markers/';              // Path to marker images
	      var urlPrefix = '/properties';        // Url Prefix ('properties')
	      var markerData = $('.marker-data').data('marker');        // Marker data array

	      var $coords = $(coords);                    // Coordinates
	      var lat = parseFloat($coords.data('lat'));            // Latitude value from Coordinate data-lat attribute
	      var lng = parseFloat($coords.data('lng'));            // Longitude from Coordinate data-lng attribute
	      var defaultLocation = lat + ',' + lng;                  // Default Location ('Seattle, Wa')

	      var mapTarget = mapTarget;                    // new Map Target ('map' or 'detail-map')
	      var map = new google.maps.Map(
	        document.getElementById(mapTarget),
	        {
	          center: new google.maps.LatLng(lat, lng),
	          zoom: 17,
	          scrollwheel: false,
	          mapTypeId: google.maps.MapTypeId.ROADMAP,
	        }
	      );

	      var marker = new google.maps.Marker({
	        map: map,
	        icon: markerPath + '/marker.png',
	        position: map.getCenter(),
	        animation: google.maps.Animation.DROP,
	      });

	      google.maps.event.trigger(map, 'resize');

	      $(function() {
	        map.setOptions({ maxZoom: 14, scrollwheel: false, mapTypeId: google.maps.MapTypeId.ROADMAP });

	        $.get('/templates/component_mp3_property_detail_infowindow.emt', function(output) {
	          infoWindowTemplate = output;
	          var latlng = new google.maps.LatLng(lat, lng);
	          var index = 1;
	          createMarker(latlng);
	        });

	      });

	      function createMarker(latlng) {
	        var data = markerData;
	        data.linkurl = urlPrefix + "/" + data.linkurl;

	        var html = Mustache.to_html(infoWindowTemplate, data);
	        google.maps.event.addListener(marker, 'click', function() {
	          infoWindow.setContent(html);
	          infoWindow.open(map, marker);
	        });
	      }
	    }
	},

	/**
	 * Utility object for commonly used functions
	 */
	UTIL:  {
		/**
		 * noFouc - Avoid unstyled flash
		 *
		 * This function is intended to reduce a 'flash of unstyled content' at the time of DOMContentLoaded event being triggered.
		 * Items with '.no-fouc' class will be display: none; on initial load. When dom is ready and '.no-fouc' class is removed the item will then show.
		 * 
		 * @usage FEATURES.UTIL.noFouc();
		 *
		 * Dependencies
		 * - jQuery
		 */
		noFouc: function() {
			// Caching document object as variable (reduce jQuery lookups)
			var $doc = $(document);

			$('.no-js').removeClass('no-js');

			$doc.ready(function() {
				$('.no-fouc').removeClass('no-fouc');
			});
		},
		/**
		 * Search - Move all search functionality here to the router and not inline.
		 *
		 * Search will perform on value passed through input when user hits "ENTER" or on click of button indicated by btn parameter
		 * 
		 * @usage FEATURES.UTIL.siteSearch();
		 *
		 * Dependencies
		 * - jQuery
		*/
		siteSearch: function(input, btn) {
			// General search functionality
	    	function search() {
	    		var searchTerm = $(input).val();
	    		
			    window.open('/search/' + searchTerm,'_self');
	    	}

			// Search will perform when you press "enter" key in search input
			$(input).keypress(function(e){
				if(e.which == 13) {
					search();
				}
			});

			// Search will happen when this button is clicked
	    	$(btn).click(function(e){
	    		e.preventDefault();
	    		search();
	    	});
		},

		/**
		 * Main Menu Toggle (Mobile and Frontpage)
		 *
		 * Listens to click event and fire animation classes on click.
		 */
		navSrchToggle: function(toggle) {

			var $navToggle = $(toggle);

			$navToggle.on('click', function(event) {
				var menuOverlay = $(this).attr('href');

				$('.overlay-toggle').not(toggle).removeClass('on');
				$('.menu-srch-overlay').not(menuOverlay).removeClass('on').fadeOut();

				$(toggle).toggleClass('on');
				$(menuOverlay).toggleClass('on').fadeToggle();
				event.preventDefault();
			});
			$(document).keyup(function(e) {
				if (e.keyCode === 27 && $navToggle.hasClass('on')) {
					$navToggle.toggleClass('on');
					$menuOverlay.toggleClass('on');
					event.preventDefault();
				}
			});
			$( window ).resize(function() {
				$('.overlay-toggle').removeClass('on');
				$('.menu-srch-overlay').removeClass('on').fadeOut();
			});
		},

		/**
	   	* Accordion - Expand/Collapse
	   	*/
		accordion: function(item, content, trigger) {
		    var $item = $(item);
		    var $content = $(content);
		    var $trigger = $(trigger);

		    $content.hide();

		    $(trigger).click( function(e) {

		      $(this).siblings($content).slideToggle(300);
		      $(this).parent().toggleClass('active');

		      // Remove any other active classes
		      $content.not( $(this).siblings() ).slideUp(300);
		      $item.not( $(this).parent() ).removeClass('active');

		      e.preventDefault();
		      e.stopPropagation();
		      return false;
		    });

		    $content.each(function() {
		      if ($.trim($(this).html()) === '') {
		        $(this).parent().remove();
		      }
		    });
		},

		/**
		 * Header Transition - on Scroll, add class when the window has scrolled past navbar height.
		 *
		 * @usage FEATURES.UTIL.headerTransition(header, scrollClass);
		 *
		 * Dependencies
		 * - jQuery
		 */
		headerTransition: function(header, scrollClass) {
			var $header = $(header);
			var $navbarHeight = $header.outerHeight();

			var $win = $(window);
			var $doc = $(document);

			$win.bind('scroll', function(event) {
				var scroll = $doc.scrollTop();
				if (scroll > $navbarHeight) {
					$header.addClass(scrollClass);
				} else {
					$header.removeClass(scrollClass);
				}
			});
		},

		stickyHeader: function() {
			var dist = $('header').offset().top; //sticky header

		    $(window).scroll(function(e) {

		        if ($(window).scrollTop() > dist) {
		            $('body').addClass('sticky');
		        } else {

		            $('body').removeClass('sticky');
		        }

		    });
		},

		fadeNav: function() {
			$(document).ready(function(e) {
		        $('.navbar-toggle').click(function(e) {
		            $('#myNavbar').fadeToggle();
		        });
		    });
		},

		equalHeight: function(){
			
	        equalheight = function(container) {
	            var currentTallest = 0,
	                currentRowStart = 0,
	                rowDivs = new Array(),
	                $el,
	                topPosition = 0;
	            $(container).each(function() {

	                $el = $(this);
	                $($el).height('auto');
	                topPostion = $el.position().top;

	                if (currentRowStart != topPostion) {
	                    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
	                        rowDivs[currentDiv].height(currentTallest);
	                    }
	                    rowDivs.length = 0; // empty the array
	                    currentRowStart = topPostion;
	                    currentTallest = $el.height();
	                    rowDivs.push($el);
	                } else {
	                    rowDivs.push($el);
	                    currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	                }
	                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
	                    rowDivs[currentDiv].height(currentTallest);
	                }
	            });
	        };

	        $(window).load(function() {
	            equalheight('.eh');
	        });

	        $(window).resize(function() {
	            equalheight('.eh');
	        });

	        $(window).load(function() {
	            equalheight('.eh1');
	        });

	        $(window).resize(function() {
	            equalheight('.eh1');
	        });

	        $(window).load(function() {
	            equalheight('.eh3');
	        });

	        $(window).resize(function() {
	            equalheight('.eh3');
	        });

	        $(window).load(function() {
	            equalheight('.eh4');
	        });

	        $(window).resize(function() {
	            equalheight('.eh4');
	        });

	        $(document).ready(function() {
		        equalheight(' .eh');
		        equalheight(' .eh1');
		        equalheight(' .eh3');
		        equalheight(' .eh4');
		    });
		},
	},

	/**
	 * Menu object for mmenu configuration options
	 */
	MENU: {
		/**
		 * Mmenu offcanvas default
		 *
		 * @usage FEATURES.MENU.mmenu();
		 * Dependencies
		 * - jQuery
		 * - mmenu (http://mmenu.frebsite.nl/documentation/options/)
		 *         (http://mmenu.frebsite.nl/documentation/options/configuration.html)
		 *         (http://mmenu.frebsite.nl/documentation/extensions/)
		*/
		mmenu: function(menu, toggleBtn, options) {
			var $menu = $(menu);
			var $icon = $(toggleBtn);

			// Init mmenu with options
			$menu.mmenu(options);

			var API = $menu.data( "mmenu" );

			// When click trigger button open or close
			$icon.on( "click", function() {
			   API.open();
			   API.close();
			});

			// If menu is opened add active class
			API.bind( "opened", function() {
			   setTimeout(function() {
			      $icon.addClass( "is-active" );
			   }, 100);
			});

			// If menu is closed remove active class
			API.bind( "closed", function() {
			   setTimeout(function() {
			      $icon.removeClass( "is-active" );
			   }, 100);
			});
		},
	},
	/**
	 * Plugin object for extended functionality with use of jquery plugins
	 */
	PLUGIN:  {

		/** 
			Responsive Image Mapping (MP3 Overview)
		*/
		imgMap: function() {
			$(window).load(function(e) {
		        $(function() {
		            $('.map_1, .map_2, .map_3, .map_4, .map_5, .map_6, .map_7, .map_8, .map_9, .map_10,map_11').maphilight();
		        });

		        $(document).ready(function(e) {
		            $('img[usemap]').rwdImageMaps();
		            $('img[usemap]').maphilight({
		                stroke: false,
		                fillColor: '000000',
		                fillOpacity: 0.2
		            });

		            //$("map area").click(function(){
		            //          alert('clicked')
		            //      })
		        });

		        jQuery(window).bind('resize orientationchange', function(e) {
		            var windowWidth = $(window).width();
		            jQuery(window).resize(function() {
		                jQuery('img[usemap]').maphilight({
		                    stroke: false,
		                    fillColor: '000000',
		                    fillOpacity: 0.7
		                });
		                if (windowWidth != $(window).width()) {

		                    return;
		                }
		            });
		        });
		    });
		},

		/**
		 * Match Height - Setup
		 *
		 * Add $target class to items that should all be the same height. 
		 * Pass options through the router via options parameter.
		 * 
	 	 * @param target {string} - pass in a string or html class to match height on.
		 * @usage FEATURES.PLUGIN.matchHeight('.match');
		 *
		 * Dependency:
		 * - jQuery
		 * - jQuery.matchHeight.js (https://github.com/liabru/jquery-match-height)
		 */
		matchHeight: function(target, options) {
			// Cache target selector as variable (reduce jQuery lookups)
			var $target = $(target);

			$target.matchHeight(options);
		},
		/**
		 * Slick Slider
		 *
		 * Initialize slick slider and configure via settings in router.js file
		 * 
		 * @param $target {string} - pass in a string or html class of slides container.
		 * @usage FEATURES.PLUGIN.slick('.myslider');
		 *
		 * Dependencies
		 * - jQuery
		 * - slick.js (http://kenwheeler.github.io/slick/)
		*/
		slick: function(target, settings) {
			var $target = $(target);

		 	$target.slick(settings);
		},

		propertySlider: function(target) {
		    $target = $(target);
		    $target.slick({
		      autoplay: true,
		      slidesToShow: 1,
		      slidesToScroll: 1,
		      arrows: false,
		      fade: true,
		      dots: true,
		      customPaging : function(slider, i) {
		        var thumb = $(slider.$slides[i]).data('thumb');
		        return '<a><img src="' + thumb + '"></a>';
		      },
		    });
		},
	},
};