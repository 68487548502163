/**
 *     ▄▄▄▄▄▄▄▄▄▄▄  ▄       ▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄        ▄                                  
 *    ▐░░░░░░░░░░░▌▐░▌     ▐░▌▐░░░░░░░░░░░▌▐░░▌      ▐░▌                                 
 *    ▐░█▀▀▀▀▀▀▀█░▌ ▐░▌   ▐░▌ ▐░█▀▀▀▀▀▀▀█░▌▐░▌░▌     ▐░▌                                 
 *    ▐░▌       ▐░▌  ▐░▌ ▐░▌  ▐░▌       ▐░▌▐░▌▐░▌    ▐░▌                                 
 *    ▐░█▄▄▄▄▄▄▄█░▌   ▐░▐░▌   ▐░▌       ▐░▌▐░▌ ▐░▌   ▐░▌                                 
 *    ▐░░░░░░░░░░░▌    ▐░▌    ▐░▌       ▐░▌▐░▌  ▐░▌  ▐░▌                                 
 *    ▐░█▀▀▀▀▀▀▀█░▌   ▐░▌░▌   ▐░▌       ▐░▌▐░▌   ▐░▌ ▐░▌                                 
 *    ▐░▌       ▐░▌  ▐░▌ ▐░▌  ▐░▌       ▐░▌▐░▌    ▐░▌▐░▌                                 
 *    ▐░▌       ▐░▌ ▐░▌   ▐░▌ ▐░█▄▄▄▄▄▄▄█░▌▐░▌     ▐░▐░▌                                 
 *    ▐░▌       ▐░▌▐░▌     ▐░▌▐░░░░░░░░░░░▌▐░▌      ▐░░▌                                 
 *     ▀         ▀  ▀       ▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀        ▀▀                                  
 *          ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄         ▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄ 
 *         ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌
 *         ▐░█▀▀▀▀▀▀▀█░▌▐░█▀▀▀▀▀▀▀█░▌▐░▌       ▐░▌ ▀▀▀▀█░█▀▀▀▀ ▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀▀▀▀█░▌
 *         ▐░▌       ▐░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░▌          ▐░▌       ▐░▌
 *         ▐░█▄▄▄▄▄▄▄█░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░█▄▄▄▄▄▄▄▄▄ ▐░█▄▄▄▄▄▄▄█░▌
 *         ▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌
 *         ▐░█▀▀▀▀█░█▀▀ ▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀█░█▀▀ 
 *         ▐░▌     ▐░▌  ▐░▌       ▐░▌▐░▌       ▐░▌     ▐░▌     ▐░▌          ▐░▌     ▐░▌  
 *         ▐░▌      ▐░▌ ▐░█▄▄▄▄▄▄▄█░▌▐░█▄▄▄▄▄▄▄█░▌     ▐░▌     ▐░█▄▄▄▄▄▄▄▄▄ ▐░▌      ▐░▌ 
 *         ▐░▌       ▐░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌     ▐░▌     ▐░░░░░░░░░░░▌▐░▌       ▐░▌
 *          ▀         ▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀       ▀       ▀▀▀▀▀▀▀▀▀▀▀  ▀         ▀ 
 *                                                                                                                                             
 * -------------------------------------------------------------------------------------------------------------------------------
 *  "An Axon is the long threadlike part of a nerve cell along which impulses are conducted from the cell body to other cells."
 *
 * @author 
 *      Cameron Van Orman
 *      <cameron@efelle.com>
 * 		Slack @cameronv
 * 
 * @reference
 * 		Introduction : http://daux.efellemedia.com/SOP/master/axon-router/introduction
 *		Router Javascript: http://daux.efellemedia.com/SOP/master/axon-router/router-js
 *      Code Standards: http://daux.efellemedia.com/SOP/master/code-standards/javascript
 *
 * @usage
 *      Store javascript functions under the FEATURES namespace to give your functions global scope.
 *      Pass your functions to the Site Router (router.js) under the relevant page name. 
 *		Page names are defined by a body class on your template (.emt) file.
 *
 *      NOTE: You must have a body class on the template you're targeting in order for your page specific scripts to run.
 *      i.e <body class='home'> if you're on the frontpage template.
 * 
 *      NOTE: In order for your javascript to run on a specific page you need to declare 
 *		the function in the FEATURES global namespace, then call the function in router.js 
 *		where you've setup your page specific body class.
 *
 *
 * PAGE SETUP 
 * ----------
 * 
 * 	DOM-based Routing
 * 	Based on http://goo.gl/EUTi53 by Paul Irish
 *	
 * 	NOTE: Only fires on body classes that match. If a body class contains a dash,
 * 	replace the dash with an underscore when adding it to the object below.
 *
 * 	.noConflict()
 * 	The routing is enclosed within an anonymous function so that you can 
 * 	always reference jQuery with $, even when in .noConflict() mode.
 */

(function($) {

	/**
	 * @var AXONROUTER
	 * 		Use this variable to set up the common and page specific functions. If you
	 * 		rename this variable, you will also need to rename the namespace variable site router.
	 *
	 * @usage
	 *      var AXONROUTER = {
	 *          // All pages
	 *          common: {
	 *              init: function() {
	 *                  // JavaScript to be fired on all pages
	 *                  FEATURES.yourFunctionName();
	 *              }
	 *          },
	 *      };
	 */
	var AXONROUTER = {

		/**
		 * All pages
		 */
		common: {
			init: function() {
				// JavaScript to be fired on all pages
				FEATURES.deferImages('.lazy');
				FEATURES.UTIL.noFouc();
				FEATURES.UTIL.siteSearch('#desktop-search', '#search-btn-desktop');
				FEATURES.UTIL.siteSearch('#mobile-search');
				FEATURES.UTIL.stickyHeader();
				FEATURES.UTIL.fadeNav();
				FEATURES.UTIL.equalHeight();
				FEATURES.UTIL.navSrchToggle('.js-nav-search-toggle');

				FEATURES.PLUGIN.matchHeight('.match');

				// Ensure that you create a '.scrolling' class in your CSS with an animation.
				FEATURES.UTIL.headerTransition('#page-header', '.scroll');

				// FEATURES.MENU.mmenu('#mobile-menu','#nav-toggle',{
				// 	navbar: {
				//         title: ""// Empty string for no title on first panel
				//     },
	   //              offCanvas: {
				// 		position: "right"
	   //              },
	   //              extensions: [
	   //              	// More effects at http://mmenu.frebsite.nl/documentation/extensions/effects.html
			 //            "effect-menu-slide",
			 //            "effect-listitems-slide"
			 //        ]
	   //    		});
			}
		},

		/** 
		 * Home page
		 */
		home: {
			init: function() {
				// JavaScript to be fired on the home page
			}
		},

		/**
		 * Subpage
		 */
		subpage: {
			init: function() {
				// JavaScript to be fired on the subpages
			}
		},

		/** 
		 * Profile Overview page
		 * (note the change from profiles-overview to profile_overview)
		 */
		mp3: {
			init: function() {
				// JavaScript to be fired on the mp3 detail page
				// FEATURES.UTIL.fadeNav();
				FEATURES.PLUGIN.imgMap();
			}
		},

		/** 
		 * Profile Overview page
		 * (note the change from profiles-overview to profile_overview)
		 */
		mp3_detail: {
			init: function() {
				// JavaScript to be fired on the mp3 detail page
				FEATURES.PLUGIN.propertySlider('.property-slider');
				FEATURES.gMaps.initialize('detail-map','.detail-coords');
				FEATURES.UTIL.accordion('.accordion-item','.accordion-content','.accordion-heading');
			}
		},

		/** 
		 * Profile Overview page
		 * (note the change from profiles-overview to profile_overview)
		 */
		profile_overview: {
			init: function() {
				// JavaScript to be fired on the profile overview page
			}
		},

		/**
		 * Profile Detail page
		 * (note the change from profiles-detail to profile_detail)
		 */ 
		profile_detail: {
			init: function() {
				// JavaScript to be fired on the profile detail page
			}
		},

		/** 
		 * Service Category page
		 * (note the change from service-overview to service_overview)
		 */
		service_overview: {
			init: function() {
				// JavaScript to be fired on the service overview page
			}
		},

		/** 
		 * Service Category page
		 * (note the change from service-category to service_category)
		 */
		service_category: {
			init: function() {
				// JavaScript to be fired on the service category page
			}
		},

		/** 
		 * Service Product page 
		 * (note the change from service-product to service_product)
		 */
		service_product: {
			init: function() {
				// JavaScript to be fired on the service product page
			}
		},
	};

	/**
	 * SITE ROUTER 
	 * ----------
	 * 
	 * 	The routing fires all common scripts, followed by the page specific scripts.
	 * 	Add additional events for more control over timing e.g. a finalize event
	 */
	var UTIL = {
		fire: function(func, funcname, args) {
			var namespace = AXONROUTER;
			funcname = (funcname === undefined) ? 'init' : funcname;
			if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			UTIL.fire('common');

			$.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
				UTIL.fire(classnm);
			});
		}
	};
	 
	$(document).ready(UTIL.loadEvents);
 
})(jQuery); // Fully reference jQuery after this point.